
    @import url("https://fastly.jsdelivr.net/npm/vant@4/lib/index.css");

    //  @import url("https://unpkg.com/element-plus/dist/index.css");
    html,
    body {
        margin: 0;
        padding: 20px;
        width: 100%;
        height: 100%;
    }

    #chat {
        // display: flex;
        // height: 100vh;
        // overflow: hidden;
        position: absolute;
        height: 100%;
        width: 100%;
        overflow: hidden;
        // overflow-y: auto;
    }

    .chatBox {
        width: 100%;
        height: 100%;
        background-color: #fff;
        display: flex;
        flex-direction: column;

        // box-sizing: content-box;
        // border-radius: 0.625rem;
        .chatBox-top {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            padding: 0.56rem 1rem;
            box-sizing: border-box;
            /* height: 3rem; */
            background-color: #1989fa;
        }

        .chatBox-middle {
            background-color: #fff;
            overflow: auto;
            // flex:1;
            height: calc(100% - 12rem);

            .chatInfo {
                width: 100%;
                height: 100%;
                padding: 1rem;
                overflow: auto;
                box-sizing: border-box;
            }
        }

        .chatBox-bottom {
            // height: 10rem;
            padding-top: 1rem;
        }
    }

    .chatBox-top-imgBox {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .chatBox-top-text {
        padding-left: 1.25rem;
        font-size: 1rem;
        color: #fff;
        flex: 1;
        align-items: center;
        display: flex;
    }

    .chatBox-textarea {
        width: 100%;
        border: solid 1px #eee;
    }

    .gpt-message-box {
        width: 100%;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
    }

    .user-box-img {
        display: flex;
    }

    .chat-message {
        margin: 0 1.25rem;
        position: relative;
    }

    .chat-message-name {
        font-size: 0.875rem;
        color: #888;
        display: flex;
        flex-direction: row;
    }

    .nowDate {
        margin: 0 0.625rem;
    }

    #chat .chat-message-text {
        margin-top: 0.85125rem;
        max-width: 40rem;
        padding: 0rem 1rem;
        background-color: #f8f8f8;
        border-radius: 0.5rem;
        float: left;
        table-layout: fixed;
        word-break: break-all;
        overflow: hidden;
    }

    #chat .chat-message-text span {
        font-size: 0.9375rem;
        line-height: 1.5;
    }

    #chat .chat-message-text span-code {
        font-size: 0.9375rem;
        line-height: 1;
    }

    #chat .my-message-box {
        flex-direction: row-reverse;
    }

    #chat .chat-message-name1 {
        flex-direction: row-reverse;
    }

    #chat .chat-message-text1 {
        float: right;
    }

    .pc {
        height: 100%;
        background-color: #fff;

        .header {
            padding: 0 !important;
            height: auto !important;
            -webkit-box-shadow: -7px 3px 10px 0 rgba(255, 255, 255, .06);
            box-shadow: -7px 3px 10px 0 rgba(255, 255, 255, .06);

            .chatBox-top {
                width: 100%;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                padding: 0.56rem 1rem;
                box-sizing: border-box;
                background-color: #1989fa;
            }

        }

        .side {
            padding: 5px;
            width: 200px;
            display: flex;
            flex-direction: column;
            border-right: 1px solid #f4f4f4;

            .tools {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
            }

            .footer {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 2rem;
                padding: 0.2rem;
                border-top: #f4f4f4 1px solid;
            }

            .session-list {
                // display: flex;
                align-items: center;
                padding: 10px;
                flex-direction: column;
                align-items: center;
                overflow-x: hidden;
                overflow-y: auto;
                /* overflow: auto; */
                // height: calc(100% - 260px);
                .session-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: #dadfe5 1px solid;
                    width: 100%;
                    border-radius: 3px;
                    padding: 10px 0;
                    margin-bottom: 10px;
                    cursor: pointer;

                    .session-name {
                        color: #333;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }

                .session-active {
                    border: #1989fa 1px solid;

                    .session-name {
                        color: #1989fa;
                        font-weight: bold;
                    }
                }
            }
        }

        .main {
            padding: 10px;
            height: 100%;
            box-sizing: border-box;

            .chatBox-middle {
                background-color: #fff;
                overflow: auto;
                // flex:1;
                height: calc(100% - 8.2rem);
                .chatInfo {
                    width: 100%;
                    height: 100%;
                    padding: 1rem;
                    overflow: auto;
                    box-sizing: border-box;
                }
            }

            .chatBox-bottom {
                // height: 10rem;
                position: relative;
                padding-top: 1rem;
            }
            .chatBox-message-input{
                min-height: 33px;
                width: 100%;
                margin: 0 10px;
                padding: 15px 15px 15px 50px;
                border-radius: 26px;
                font-size: 14px;
                border: solid 1px #eee;
            }
            .chatBox-message-input-img{
                min-height: 33px;
                width: 200px;
                margin: 10px 0px;
                border: solid 1px #f1f1f1;
                border-radius: 6px;
            }
            .chatBox-message-input-img-del{
                position: absolute;
                top: 30px;
                background: rgba(255, 0, 0, 0.7);
                color: white;
                border-radius: 50%;
                //padding: 5px;
                cursor: pointer;
                font-size: 16px;
                line-height: 16px;
                text-align: center;
                width: 18px;
                height: 18px;
            }
            .chatBox-message-input-text{
                min-height: 33px;
                width: 100%;
                font-size: 14px;
                border: none;
            }
        }
    }
